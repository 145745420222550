<template>
  <div class="login-box">
    <div class="login-logo">
      <strong>Email</strong>Platform
    </div>
    <div class="login-box-body">
      <p class="login-box-msg">Sign in to start your session</p>
      <form action="#" @submit.prevent="submit">
        <div class="form-group has-feedback">
          <input class="form-control" type="email" placeholder="Email" autocomplete="username" v-model="formData.email">
          <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
        </div>
        <div class="form-group has-feedback">
          <input class="form-control" type="password" placeholder="Password" autocomplete="current-password" v-model="formData.password">
          <span class="glyphicon glyphicon-lock form-control-feedback"></span>
        </div>
        <div class="form-group no-margin">
          <div class="row">
            <div class="col-xs-7 col-sm-8">
              <div class="checkbox check">
                <label>
                  <input type="checkbox" v-model="formData.remember">
                  <span class="ico"></span>
                  <span>Remember Me</span>
                </label>
              </div>
            </div>
            <div class="col-xs-5 col-sm-4">
              <button type="submit" class="btn btn-primary btn-block btn-flat" :disabled="loading">Sign In</button>
            </div>
          </div>
        </div>
        <div class="form-group has-error" v-if="!!formError">
          <span class="help-block">{{ formError }}</span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  metaInfo: {
    title: 'Login'
  },
  data () {
    return {
      formData: {
        email: '',
        password: '',
        remember: false
      },
      formError: null,
      loading: false
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    submit: async function () {
      if (this.loading) return

      this.loading = true
      this.formError = null

      this.signIn(this.formData)
        .then(() => {
          const nextRoute = this.$route.query.nextUrl ?? '/'
          this.$router.replace({ path: nextRoute })
        })
        .catch((error) => {
          this.formError = error.response?.data?.message
          this.loading = false
        })
    }
  }
}
</script>
